import "./App.scss";

function App() {
  return (
    <div className="App">
      <main class="page-wrap">
        <section>
          <h2 class="heading greeting">
            hi<span>.</span>
          </h2>
          <h1 class="heading title">
            I<span>'</span>m Jeremy Bartels
          </h1>
        </section>

        <section class="description">
          <article class="descriptor">
            <header>
              <h1>husband, father, developer, gamer, and occasional cook.</h1>
            </header>
          </article>
        </section>
      </main>
    </div>
  );
}

export default App;
